import { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

function Admin({ handleOnLogout }) {
    const [responses, setResponses] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const getAllResponses = () => {
        axios.get("https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/15_anni_banco_daunia.json")
            .then(response => {
                if (response.status === 200) {
                    setResponses(response.data);
                }
            })
            .catch(err => {
                console.log("error", err);
                window.Swal.fire({
                    html: "Errore nel recuperare le risposte, aggiornare la pagina",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#F68B1F",
                    cancelButtonColor: "#395E14",
                    confirmButtonText: "OK!"
                })
            });
    };

    const handleOnDeleteResponse = (id, name, surname) => {
        window.Swal.fire({
            text: `Sicuro di voler eliminare la risposta di ${name} ${surname}?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#F68B1F",
            cancelButtonColor: "#395E14",
            confirmButtonText: "Si",
            cancelButtonText: "No"
        }).then(result => {
            if (result.isConfirmed) {
                axios.delete(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/15_anni_banco_daunia/${id}.json`)
                    .then(response => {
                        if (response.status === 200) {
                            window.Swal.fire({
                                html: "Partecipazione eliminata con successo!",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#F68B1F",
                                cancelButtonColor: "#395E14",
                                confirmButtonText: "OK!"
                            }).then(result => {
                                if (result) {
                                    getAllResponses();
                                }
                            })
                        }
                    })
            }
        }).catch(err => {
            if (err) {
                window.Swal.fire({
                    html: "Errore nella cancellazione della partecipazione. Riprovare",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#F68B1F",
                    cancelButtonColor: "#395E14",
                    confirmButtonText: "OK!"
                })
            }
        })
    };

    useEffect(() => {
        getAllResponses();
    }, []);

    const exportToExcel = () => {
        if (!responses) return;

        // Calcola i dati formattati e la somma di hostNumber
        let totalParticipants = 0;
        const formattedData = Object.keys(responses).map((key, index) => {
            const hostNumber = parseInt(responses[key].hostNumber);
            totalParticipants += hostNumber;
            return {
                Numero: index + 1,
                Nome: responses[key].name,
                Cognome: responses[key].surname,
                Email: responses[key].email,
                Telefono: responses[key].phone,
                "Ospiti": hostNumber,
                Associazione: responses[key].association,
            };
        });

        // Aggiunge una riga in fondo con il totale dei partecipanti
        formattedData.push({
            Numero: '',
            Nome: '',
            Cognome: '',
            Email: '',
            Telefono: '',
            "Ospiti": `Totale: ${totalParticipants}`,
            Associazione: '',
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Imposta la larghezza minima delle colonne
        const columnWidths = [
            { wpx: 50 },  // Numero
            { wpx: 170 }, // Nome
            { wpx: 170 }, // Cognome
            { wpx: 200 }, // Email
            { wpx: 170 }, // Telefono
            { wpx: 75 },  // Numero Ospiti
            { wpx: 250 }  // Associazione
        ];
        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Risposte");

        XLSX.writeFile(workbook, "Risposte_invitati_15_anni_Banco_Daunia.xlsx");
    };

    const getTotalParticipants = () => {
        if (!responses) return 0;
        return Object.keys(responses).reduce((total, key) => {
            return total + parseInt(responses[key].hostNumber, 10);
        }, 0);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredResponses = Object.keys(responses || {}).filter(key =>
        responses[key].name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        responses[key].surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        responses[key].association.toLowerCase().includes(searchTerm.toLowerCase()) ||
        responses[key].phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        responses[key].email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 text-center py-5">
                    <h1 className="text-primary">Risposte degli invitati</h1>
                </div>
            </div>
            <div className="row justify-content-center align-items-center">
                <div className="col-12 px-3 px-md-3 px-lg-5">
                    {responses ? (
                        <>
                            <div className="col-12 my-3 mb-4">
                                Numero totale partecipanti: <strong>{getTotalParticipants()}</strong>
                            </div>
                            <div className="col-12 col-md-6 col-lg-8 my-4 mx-auto">
                                <input type="search" className="form-control" placeholder="Cerca" onChange={handleSearch} />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="bg-secondary text-white">#</th>
                                        <th className="bg-secondary text-white">Nome</th>
                                        <th className="bg-secondary text-white">Cognome</th>
                                        <th className="bg-secondary text-white">Email</th>
                                        <th className="bg-secondary text-white">Telefono</th>
                                        <th className="bg-secondary text-white">Numero Ospiti</th>
                                        {/*<th className="bg-secondary text-white">Invitato da</th>*/}
                                        <th className="bg-secondary text-white">Associazione</th>
                                        <th className="bg-secondary text-white">Azioni</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredResponses.map((key, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{responses[key].name}</td>
                                            <td>{responses[key].surname}</td>
                                            <td>{responses[key].email}</td>
                                            <td>{responses[key].phone}</td>
                                            <td>{responses[key].hostNumber}</td>
                                            {/*<td>{responses[key].inviter}</td>*/}
                                            <td>{responses[key].association}</td>
                                            <td onClick={() => handleOnDeleteResponse(key, responses[key].name, responses[key].surname)} className="trash-icon">🗑️</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-center py-3">
                                <button onClick={exportToExcel} className="btn btn-primary">Esporta in Excel</button>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">
                            Nessuna risposta presente
                        </div>
                    )}
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                <div className="col-12 text-center pt-5">
                    <button onClick={handleOnLogout} className="btn btn-secondary">Logout</button>
                </div>
                <div className="col-12 text-center pb-5 py-4">
                    <a className="text-decoration-none text-primary fw-bolder" href="/"> Vai alla home</a>
                </div>
            </div>
        </div>
    );
}

export default Admin;
