let associationOptions = [
    "Amici volontari di Anzano",
    "Ass. Volontari per il Popolo",
    "Banco di solidarietà ing. Carlo di Muro",
    "Bersaglieri",
    "Bethel Cerignola",
    "Bethel Fg",
    "Bethel Andria",
    "Casal Trinità",
    "C.I.S.A.",
    "Centro di Solidarietà Padre Maestro",
    "Centro di Solidarietà",
    "Ciao Bambini",
    "Comune di Chieuti",
    "Comune di Orsara di Puglia",
    "Comune di San Martino in Pensilis",
    "Comune di Tufara",
    "Misericordia Canosa di Puglia",
    "Misericordia di Barletta",
    "Misericordia di Grottole",
    "Misericordia di Orta Nova",
    "Misericordia Andria",
    "Misericordia Molfetta",
    "Suore Salesiane dei Sacri Cuori Fg",
    "Suore Salesiane Sacri Cuori Barletta",
    "Cuore D'Angelo",
    "Protezione Civile Deliceto",
    "Devoti di Padre Pio",
    "Diffondiamo il vangelo nel Gargano",
    "Divina Carità",
    "DON ORIONE - Santuario Incoronata",
    "E.R.A.",
    "E'Ambiente",
    "Famiglie Numerose",
    "Fede Speranza Amore",
    "FIDAS -S.G.Rotondo",
    "FIDAS - Zapponeta",
    "FIDAS - Carapelle",
    "FIDAS -  Orta Nova",
    "Fondazione Fasano-Potenza",
    "Fratelli della Stazione",
    "Misericordia di Castelluccio Valmaggiore",
    "Genoveffa de Troia",
    "I Falchi",
    "Il Buon Samaritano",
    "Il Granaio",
    "Istituto Maria Ausiliatrice - Cerignola",
    "La Fenice",
    "La Rondine",
    "Laboratorio Verde di Fareambiente",
    "L'alba di Zapponeta",
    "Maria Madre di Misericordia e San Pio",
    "Meta",
    "Misericordia Maschito",
    "N.I.T.A.",
    "Oratorio/circolo Anspi Carlo Acutis",
    "Orizzonti",
    "Parrocchia Annunciazione del Signore",
    "Parrocchia B.V.M. Assunta in Cielo CATTEDRALE Fg",
    "Parrocchia B.V.M. Regina della Pace",
    "Parrocchia Beata Maria Vergine del Rosario",
    "Parrocchia Cristo Re",
    "Parrocchia Gesù Buon Pastore",
    "Parrocchia Gesù e Maria",
    "Parrocchia Natività di Maria Vergine",
    "Parrocchia S. Alfonso Maria dei Liguori",
    "Parrocchia S. Antonio da Padova",
    "Parrocchia S. Bernardino",
    "Parrocchia S. Camillo de Lellis",
    "Parrocchia S. Ciro",
    "Parrocchia S. Filippo Neri (Barletta)",
    "Parrocchia S. Filippo Neri Fg",
    "Parrocchia S. Giacomo M. Ap.",
    "Parrocchia S. Giovanni Battista",
    "Parrocchia S. Giuseppe (Manfredonia)",
    "Parrocchia S. Giuseppe Artigiano",
    "Parrocchia S. Luigi Gonzaga",
    "Parrocchia S. Maria delle Grazie",
    "Parrocchia S. Mauro",
    "Parrocchia S. Michele Arcangelo",
    "Parrocchia S. Nicola di Mira",
    "Parrocchia S. Paolo Apostolo",
    "Parrocchia S. Pietro",
    "Parrocchia S. Pio Decimo (Lucera)",
    "Parrocchia S. Pio X (Foggia)",
    "Parrocchia S. Tommaso Apostolo",
    "Parrocchia Sacra Famiglia",
    "Parrocchia Santa Maria Assunta (Vieste)",
    "Parrocchia Santa Maria del Carmine (Manfredonia)",
    "Parrocchia Santa Maria del Carmine Fg",
    "Parrocchia Santa Maria della Croce",
    "Parrocchia Santa Maria della Spiga",
    "Parrocchia Sant'Anna Frati Minori Cappuccini",
    "Parrocchia Spirito Santo",
    "Parrocchia SS Guglielmo e Pellegrino",
    "Parrocchia SS Pietro e Paolo",
    "Parrocchia SS Salvatore (Castelluccio)",
    "Parrocchia SS Salvatore (Foggia)",
    "Pompieri- \"A. Gramazio\"",
    "P.A.S.E.R.",
    "P.A. V. Alto Bradano",
    "Quadrifoglio",
    "Sacro Cuore",
    "SAFE",
    "Salvati per servire Odv",
    "Solidalmente Ascoli Satriano 2023",
    "Solidaunia",
    "The Angels Life",
    "TUR 27",
    "Vincenziano - sez Cerignola",
    "Vola -Cerignola",
    "ANFASS",
    "Arci",
    "Borgo Tressanti",
    "Chiesa Evangelica Pentecostale",
    "Congregazione delle suore di santa Chiara",
    "Congregazione Missionarie Figlie del Calvario",
    "Convento  \"P.Pio giovane\"",
    "Convento frati minori cappuccini",
    "Croce Rossa Italiana Comitato di Bari",
    "Fondazione Siniscalco Ceci Emmaus",
    "Il Campanile",
    "Istituto San Tarcisio",
    "Istituto Suore Discepole di Gesù Eucaristico",
    "La Formica",
    "L'Altro Mio Figlio",
    "Le Ali della Mente",
    "Monastero della Risurrezione Clarisse Cappuccine",
    "Mondo Nuovo",
    "Paidòs",
    "Parrocchia B.V.M. Addolorata- Orta Nova",
    "San Cristoforo",
    "Seminario Diocesano S.Cuore",
    "Sulla Strada di Emmaus"
]

export default associationOptions