import {useEffect, useState} from "react";
import Admin from "./Admin";

function Login () {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLogged, setIsLogged] = useState(false)

    useEffect(() => {
        if (window.localStorage.getItem("login")) {
            setIsLogged(true)
        } else {
            setIsLogged(false)
        }
    }, []);

    const handleOnLogout = (e) => {
        if (e) {
            window.localStorage.removeItem("login")
            setIsLogged(false)
        }
    }

    const handleOnLogin = (e) => {
        e.preventDefault()

        if (username === 'daunia15' && password === 'smenduno') {
            setIsLogged(true)
            window.localStorage.setItem("login", true)
        } else {
            alert("Credenziali errate. Riprovare")
            setPassword("")
        }
    }

    return (
        <>
            {!isLogged ? (
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center py-5">
                        <div className="col-12">
                            <h1 className="text-center fw-bolder text-primary py-5">
                                Area riservata
                            </h1>
                        </div>
                        <div className="col-12 text-center fw-bolder">
                            Inserisci le credenziali per continuare
                        </div>
                        <div className="col-12 col-md-8 col-lg-5 my-5 px-4">
                            <form onSubmit={(e) => handleOnLogin(e)}>
                                <div className="mb-3">
                                    <label className="form-label">Username</label>
                                    <input onChange={(e) => setUsername(e.target.value)} value={username} type="text" className="form-control" required={true}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" className="form-control" required={true}></input>
                                </div>
                                <div className="d-flex mt-5">
                                    <button type="submit" className="btn btn-primary">Accedi</button>
                                    <a href="/" className="text-decoration-none ms-3">
                                        <button type="button" className="btn btn-secondary">Torna indietro</button>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <Admin handleOnLogout={(e) => handleOnLogout(e)}/>
            )}

        </>
    )
}

export default Login;
