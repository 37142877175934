import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {useState} from "react";
import Login from "./pages/Login";
import axios from "axios";
import associationOptions from "./associations";
import * as emailjs from "emailjs-com";

let options = associationOptions.map(option => ({ value: option, label: option }))

function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route path="/" element={<Home/>}></Route>
                  <Route path="/login" element={<Login/>}></Route>
              </Routes>
          </Router>
      </>
  );
}

function Home () {
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [hostNumber, setHostNumber] = useState("")
    const [inviter, setInviter] = useState("")
    const [association, setAssociation] = useState("")
    // eslint-disable-next-line no-use-before-define
    const associationOptions = options

    const resetForm = () => {
        setName("")
        setSurname("")
        setEmail("")
        setPhone("")
        setHostNumber("")
        setInviter("")
        setAssociation("")
    }

    const handleOnClickSubmit = (e) => {
        e.preventDefault();

        let data = {
            name: name,
            surname: surname,
            email: email,
            phone: phone,
            hostNumber: hostNumber,
            inviter: inviter,
            association: association,
        };

        // Specifica l'ID desiderato
        let customID = name + '_' + surname + '_' + phone;

        axios.put(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/15_anni_banco_daunia/${customID}.json`, data)
            .then(response => {

                emailjs.send(
                    'service_7cnshw7',
                    'template_kj5crwe',
                    {
                        to_name: name,
                        to_email: email,
                        subject: 'Conferma della tua prenotazione',
                        message: `Grazie per aver confermato la tua presenza! Ti aspettiamo giovedì 11 luglio alle 20.30 al Banco Alimentare della Daunia.\nTrovi la sede a questo link: https://maps.app.goo.gl/Wu7AFd1gHuZgMz8F9`
                    },
                    'vX2-3D1Z6JAF-H0O-'
                ).then(mail => {
                    console.log("mail sended", mail)
                })

                if (response.status === 200) {
                    window.Swal.fire({
                        html: "Grazie per aver confermato la tua presenza! <br/> Ti aspettiamo giovedì 11 luglio alle 20.30 al Banco Alimentare della Daunia",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#F68B1F",
                        cancelButtonColor: "#395E14",
                        confirmButtonText: "OK!"
                    }).then(result => {
                        if (result) {
                            resetForm()
                        }
                    })
                }
            })
            .catch(error => {
                console.log("error on sending", error);
                window.Swal.fire({
                    html: "Errore nella richiesta, si prega di riprovare",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#F68B1F",
                    cancelButtonColor: "#395E14",
                    confirmButtonText: "OK!"
                }).then(result => {
                    if (result) {
                        resetForm()
                    }
                })
                // alert("Errore nella prenotazione. Si prega di riprovare");
            });
    };


    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center min-vh-80">
                    <div className="col-8 d-none d-md-block text-center">
                        <img src="/img/banco_compleanno.png" alt="Immagine Banco Alimentare" className="img-fluid"/>
                    </div>
                    <div className="col-12 d-md-none">
                        <img src="/img/banco_compleanno_mobile.png" alt="Immagine Banco Alimentare" className="img-fluid"/>
                    </div>
                </div>
                <div className="row align-items-start justify-content-around py-5">
                    <div className="col-12 col-md-6 col-lg-5 px-4 mt-5 mt-md-0 bg-light py-5 px-3 rounded shadow-sm">
                        <div className="col-12 text-center">
                            <h2 className="text-primary fw-bolder">
                                Partecipa alla festa del Banco Alimentare della Daunia
                            </h2>
                        </div>
                        <div className="col-12 text-center pt-4 pb-2 fw-bolder">
                            Ingresso su invito. <br/>
                            È necessario confermare la presenza registrandosi al link.
                        </div>
                        <div className="col-12 text-center fw-bolder mb-5">
                            Compila i seguenti campi
                        </div>

                        <form onSubmit={(e) => handleOnClickSubmit(e)}>
                            <div className="mb-3">
                                <label className="form-label">Nome</label>
                                <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="form-control" required={true}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Cognome</label>
                                <input onChange={(e) => setSurname(e.target.value)} value={surname} type="text" className="form-control" required={true}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" className="form-control" required={true}/>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-6 pe-2">
                                    <label className="form-label">Telefono</label>
                                    <input onChange={(e) => setPhone(e.target.value)} value={phone} type="number" className="form-control" required={true}/>
                                </div>
                                <div className="col-6 ps-2">
                                    <div className="mb-3">
                                        <label className="form-label">Numero di persone</label>
                                        <select onChange={(e) => setHostNumber(e.target.value)} value={hostNumber} className="form-select" aria-label="Seleziona numero di persone" required={true}>
                                            <option value="">Seleziona opzione</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Azienda/Ente/Altro</label>
                                <input onChange={(e) => setAssociation(e.target.value)} value={association} type="text" className="form-control" required={true}></input>
                            </div>
                            <div className="mt-5">
                                <button type="submit" className="btn btn-primary">Invia</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row align-items-start justify-content-around py-3">
                    <div className="col-12 col-md-6 col-lg-5 px-4">
                        <div className="col-12 text-center pt-5 px-3">
                            <h2 className="text-primary fw-bolder">
                                Raggiungi il Banco
                            </h2>
                        </div>
                        <div className="col-12 text-center py-4 fw-bolder mb-5">
                            La festa si terrà presso la nostra sede, in via Manfredonia a Foggia.
                        </div>
                        <div className="col-12 map-container">
                            <iframe title="Mappa della sede del Banco Alimentare della Daunia" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.168695905356!2d15.55781671245844!3d41.478942290190076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1339d87068df293f%3A0xbbd0b2a416354a54!2sBanco%20Alimentare%20della%20Daunia%20%22Francesco%20Vassalli%22!5e0!3m2!1sen!2sit!4v1716136593770!5m2!1sen!2sit" width="100%" height="400" allowFullScreen="true" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-12 text-center my-4">
                            <a href="https://maps.app.goo.gl/Wu7AFd1gHuZgMz8F9" className="text-decoration-none">
                                <button className="btn btn-secondary">
                                    Raggiungi la sede
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 pb-3">
                    <div className="col-12 text-center py-2 fw-bolder">
                        <a href="/login" className="text-decoration-none text-secondary">
                            Area riservata
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default App;
